.outlined-text {
  color: white;
}

.outlined-text .outline {
  position: absolute;
  left: 0;
  top: 0;
  color: black;
  -webkit-text-stroke: 6px black;
  z-index: 2;
}

.outlined-text .fill {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
}
