.landing-herofloata {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #4d0017;

  .hero-section {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    align-content: stretch;
    flex-wrap: wrap-reverse;
  }

  .left-section,
  .right-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left-section {
    flex-grow: 1;
    width: 400px;
    max-width: 100%;
  }

  .right-section {
    position: relative;
    flex-grow: 2;
    width: 400px;
    max-width: 100%;
  }

  .floater {
    position: relative;
    top: -10%;
    width: 80%;
    aspect-ratio: 16 / 9;
    border: 3px solid white;
    border-radius: 3px;
    box-shadow: 0 2px 10px #000000;
  }

  .sample-video {
    width: 100%;
    height: 100%;
    bottom: 0;
    top: auto;
    video {
      object-fit: cover;
      object-position: 50% bottom;
    }
    z-index: 0;
  }

  .focus-shade {
    background-image: linear-gradient(
      #000000dd 0%,
      #000000dd 70%,
      #00000000 100%
    );
    z-index: 1;
  }
}
