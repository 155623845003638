.landing-herovida {
  position: relative;
  width: 100%;
  height: 100%;

  .sample-video {
    z-index: -9;
    video {
      object-fit: cover;
    }
  }

  .hero-shade {
    background-image: linear-gradient(#000000aa, #000000ee, #00000000);
    z-index: -6;
  }

  .hero-section {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
