.playerchar-con {
  position: absolute;
  image-rendering: pixelated;
  transition: opacity 1s linear;
  opacity: 1;
}

.playerchar {
  overflow: hidden;
  transform-origin: 0% 0%;
  position: relative;
  image-rendering: pixelated;
}

.playerchar-label-con {
  position: relative;
}

.playerchar-label {
  position: absolute;
  left: -100px;
  top: -150px;
  width: 200px;
  height: 50px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  transition: top 250ms ease-out;
  // overflow: hidden;
}

.playerchar-label .outline {
  text-align: center;
  width: 100%;
}

.playerchar-label .fill {
  text-align: center;
  width: 100%;
}
