.widget-page {
  position: relative;
  width: 100%;
  height: 100%;
}

.widget-progress {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.widget-layer {
  position: absolute;
  left: 50%;
  top: 100%;
  right: 0;
  bottom: 0;
}

// .widget-playerchar-layer {
// }

// .widget-fx-layer {
// }

.sprite-fx {
  position: absolute;
  transform-origin: 0% 0%;
  image-rendering: pixelated;
}

.widget-debug {
  position: absolute;
  left: 0;
  top: 0;
}

.arena {
  position: absolute;
  width: 336px;
  height: 126px;
  margin-left: -168px;
  margin-top: -126px;
  left: 50%;
  top: 150%;
  transition: top 3s linear;
  transform-origin: 50% 100%;
  image-rendering: pixelated;
}
