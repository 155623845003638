@import './widget/hp.scss';
@import './widget/widgetpage.scss';
@import './widget/outlined-text.scss';
@import './widget/hitmarker.scss';
@import './widget/playerchar.scss';

#root,
#root main {
  position: relative;
  width: 100%;
  height: 100%;
}

.hero-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
