.landing-herodemoa {
  position: relative;
  width: 100%;
  height: 100%;

  .sample-video {
    z-index: -9;
    video {
      object-fit: cover;
    }
  }

  .video-shade {
    background-color: #000000aa;
    z-index: -8;
  }

  .landing-game-page-con {
    overflow: hidden;
    z-index: -7;
  }

  .hero-shade {
    background-image: linear-gradient(#00000000, #000000ee, #00000000);
    z-index: -6;
    opacity: 1;

    transition: opacity 1s linear;
  }

  .mini-email-form {
    max-width: 400px;
  }

  .hero-section-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .hero-section {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    opacity: 1;

    transition: height 1s ease-in-out, opacity 1s linear;
  }
  .hero-controls {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &.show-demo-ctrls {
    .hero-shade {
      opacity: 0;
    }
    .hero-section {
      height: 0%;
      opacity: 0;
    }
  }
}
